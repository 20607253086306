import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import TeamStyled from "../components/styles/TeamStyles"
import SingleTeam from "../components/singleTeam"

const TeamPage = ({ data }) => {
  const { nodes } = data.allWordpressWpTeam
  const { acf } = data.wordpressPage
  const { description } = acf
  const { title, canonical } = data.wordpressPage.yoast
  const mainTeam = nodes.filter(item => separateTeam(item, "main"))
  const cooperationTeam = nodes.filter(item =>
    separateTeam(item, "cooperation")
  )
  const partnersTeam = nodes.filter(item => separateTeam(item, "partners"))

  function separateTeam(item, team) {
    console.log(item)
    return item.acf.cooperation === team ? team : ""
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : "Start - Dapr"}
        {/* {data.yoast.map(meta_value => {
          return (
            <meta
              name={meta_value.name || meta_value.property}
              content={meta_value.content}
            />
          )
        })} */}
      </Helmet>
      <Layout>
        <TeamStyled>
          <section className="background__white training__white ">
            <div className="container">
              <h1 className="heading--1">ZESPÓŁ</h1>
              <div
                className="team__content"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
              <div className="team__conteiner">
                {mainTeam.map((item, index) => (
                  <SingleTeam
                    key={`team__${index}`}
                    name={item.acf.name}
                    company_position={item.acf.company_position}
                    short_info={item.acf.short_info}
                    img={item.acf.img}
                    slug={item.slug}
                    team_mail_text={item.acf.team_mail_text}
                    team_mail_url={item.acf.team_mail_url}
                    team_phone_text={item.acf.team_phone_text}
                    team_phone_url={item.acf.team_phone_url}
                    team_linkedIn_url={item.acf.team_linkedIn_url}
                    team_linkedin_text={item.acf.team_linkedin_text}
                    cooperation={item.acf.cooperation}
                  />
                ))}
              </div>
              <h2 className="heading--2">Współpraca</h2>
              <div className="team__conteiner">
                {cooperationTeam.map((item, index) => (
                  <SingleTeam
                    key={`team__${index}`}
                    name={item.acf.name}
                    company_position={item.acf.company_position}
                    short_info={item.acf.short_info}
                    img={item.acf.img}
                    slug={item.slug}
                    team_mail_text={item.acf.team_mail_text}
                    team_mail_url={item.acf.team_mail_url}
                    team_phone_text={item.acf.team_phone_text}
                    team_phone_url={item.acf.team_phone_url}
                    team_linkedIn_url={item.acf.team_linkedIn_url}
                    team_linkedin_text={item.acf.team_linkedin_text}
                    cooperation={item.acf.cooperation}
                  />
                ))}
              </div>
              <h2 className="heading--2">Partnerzy</h2>
              <div className="team__conteiner">
                {partnersTeam.map((item, index) => (
                  <SingleTeam
                    key={`team__${index}`}
                    name={item.acf.name}
                    company_position={item.acf.company_position}
                    short_info={item.acf.short_info}
                    img={item.acf.img}
                    slug={item.slug}
                    team_mail_text={item.acf.team_mail_text}
                    team_mail_url={item.acf.team_mail_url}
                    team_phone_text={item.acf.team_phone_text}
                    team_phone_url={item.acf.team_phone_url}
                    team_linkedIn_url={item.acf.team_linkedIn_url}
                    team_linkedin_text={item.acf.team_linkedin_text}
                    cooperation={item.acf.cooperation}
                    logo={item.acf.logo}
                    link={item.acf.link}
                  />
                ))}
              </div>
            </div>
          </section>
        </TeamStyled>
      </Layout>
    </>
  )
}

export default TeamPage

export const query = graphql`
  query getTeamPage {
    wordpressPage(title: { eq: "zespół" }) {
      id
      title
      acf {
        description
        title
      }
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
    }
    allWordpressWpTeam {
      nodes {
        id
        slug
        acf {
          cooperation
          name
          company_position
          short_info
          team_mail_text
          team_mail_url
          team_phone_text
          team_phone_url
          team_linkedIn_url
          team_linkedin_text
          img {
            localFile {
              childImageSharp {
                fixed(width: 410, quality: 90) {
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  width
                  height
                }
              }
            }
          }
          link
          logo {
            localFile {
              childImageSharp {
                fixed(width: 410, quality: 90) {
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
